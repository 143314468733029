import { PageContextProps } from 'application/contexts/PageContext';
import Application from 'application/entities/Application';
import { AxiosRequestConfig } from 'axios';
import { ApiError } from 'helpers/apiError';
import { ApiRedirect } from 'helpers/apiRedirect';
import { axiosInstance as axios } from 'helpers/axiosInstance';
import { getHostNameHeader } from 'helpers/getHostNameHeader';
import getConfig from 'next/config';

export interface RequestInterface {
	pageContext?: Partial<PageContextProps>;
	url?: string;
	urlParams: {
		[key: string]: string;
	};
	query?: string;
	method?: 'GET' | 'POST';
	rewriteUrl?: string;
	requestType?: 'pageContent' | 'dictionary' | 'siblingNavigation' | 'site' | 'sitemap' | 'shortUrls';
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const queryCDA = async <TResponse = any>({
	pageContext,
	method = 'POST',
	query,
	url = '/',
	urlParams,
	rewriteUrl,
	requestType,
}: RequestInterface): Promise<TResponse> => {
	const { preview, previewData } = pageContext ?? {};
	const { publicRuntimeConfig } = getConfig() ?? {};

	const { APP_ENV, CONTENT_API_URL: contentApi } = publicRuntimeConfig ?? {};
	if (!contentApi) throw new ApiError('CONTENT_API_URL:NOTDEFINED', 500, 'Content api url is not defined');
	if (url === 'umbraco') throw new ApiError('PageNotFound', 404, 'Page not found');

	const contenturl = `${rewriteUrl ? rewriteUrl : contentApi.endsWith('/') ? contentApi.slice(0, -1) : contentApi}${url}${
		preview ? '?preview=1' : ''
	}`;
	const internalRelativeUrl = contenturl.startsWith('/');
	const hostName = getHostNameHeader({ pageContext, envs: publicRuntimeConfig });
	const hostUrl = hostName?.endsWith('/') ? hostName.slice(0, -1) : hostName;
	const requestUrl = internalRelativeUrl ? hostUrl + contenturl : contenturl;

	try {
		const options: AxiosRequestConfig = {
			url: encodeURI(decodeURIComponent(requestUrl)),
			params: urlParams,
			method: method,
			maxRedirects: 0,
			headers: {
				ContentType: 'application/json',
				'Accept-Language': Application.locale?.baseName ?? pageContext?.content?.subject?.culture,
				'Api-Key': process.env.CDA_KEY,
			},
		};

		const response = await axios(options);
		let result;
		if (method === 'POST') {
			const { data, errors } = response.data;

			if (errors) {
				throw new Error(errors[0].message, {
					cause: {
						response,
						query,
					},
				});
			}

			result = { ...data, preview: preview != null && preview ? true : false, previewData: previewData ? previewData : null };
		}

		if (method === 'GET') {
			const { data } = response;
			result = data;
		}
		return result;
	} catch (error) {
		const message = error?.response?.data?.message ?? error;
		const statusCode = error?.response?.status || 500;
		const responseErrors = error?.cause?.response?.data?.errors;
		const numberOfErrors = responseErrors?.length;
		switch (statusCode) {
			case 301:
			case 302:
			case 307:
			case 308:
				throw new ApiRedirect(statusCode, error?.response?.headers?.location);
			case 404:
				throw new ApiError('PageNotFound', 404, 'Page not found');
			case 401: // "401 Unauthorized" really means "unauthenticated"
			case 403: // "403 Forbidden" really means "unauthorized"
				return error?.response?.data?.data;
			default:
				APP_ENV != 'prod' && console.log(error.cause);
				throw new ApiError(
					'Could not grab content from CMS',
					statusCode,
					message +
						` Number of errors: ${numberOfErrors}. Query/RequestType: ${
							requestType ? requestType : error?.cause?.query
						}. Url: ${contenturl}. Host: ${hostName}`,
				);
		}
	}
};
