import { PageContextProps } from 'application/contexts/PageContext';
import { queryCmsContent } from './_api';
import { queryCDA } from './queryCDA';

export interface CDADictionaryItem {
	properties: {
		groupKeyReference: {
			properties: {
				groupKey: string;
			};
		}[];
		itemKey: string;
		value: string;
	};
}

export interface CDADictionary {
	total: number;
	items: CDADictionaryItem[];
}

export const getDictionaryFromCms = async (pageContext: Partial<PageContextProps>): Promise<Content.DictionaryItem[]> => {
	const query = `{
		dictionary {
			key
			values {
				culture
				value
			}
		}
	}`;

	const dictionary: { dictionary: Content.DictionaryItem[] } = await queryCmsContent({
		query,
		pageContext,
		requestType: 'dictionary',
	});

	return dictionary?.dictionary;
};

export const getDictionaryFromCDA = async (pageContext: Partial<PageContextProps>): Promise<Models.Dictionary> => {
	const query = `{
		dictionary {
			key
			values {
				culture
				value
			}
		}
	}`;

	const dictionary: CDADictionary = await queryCDA({
		query,
		pageContext,
		url: '/umbraco/delivery/api/v2/content',
		urlParams: {
			filter: 'contentType:dictionaryItem',
			fields: 'properties[groupKeyReference,itemKey,value]',
			expand: 'properties[groupKeyReference]',
			take: '200',
		},
		requestType: 'dictionary',
		method: 'GET',
	});

	const dictionaryModel = {
		items: dictionary?.items?.map((item) => {
			const { groupKeyReference, itemKey, value } = item?.properties ?? {};
			return {
				key: `${groupKeyReference[0].properties.groupKey}.${itemKey}`,
				value,
			};
		}),
	};

	return dictionary ? dictionaryModel : null;
};
