import { DictionaryFactory, DictionaryInterface } from 'application/repositories/dictionaryRepository';
import React, { useContext } from 'react';

const DictionaryContext = React.createContext<Partial<DictionaryInterface>>(DictionaryFactory([], process.env.DEFAULT_LOCALE ?? 'en-US'));

export interface DictionaryContextProviderProps {
	children?: React.ReactNode;
	dictionary: Content.DictionaryItem[] | Models.Dictionary;
	culture: string;
}
export const DictionaryContextProvider: React.FC<DictionaryContextProviderProps> = ({ dictionary, culture, children }) => {
	const dictionaryRepository = DictionaryFactory(dictionary, culture);
	return <DictionaryContext.Provider value={dictionaryRepository}>{children}</DictionaryContext.Provider>;
};

export function useDictionaryContext(): Partial<DictionaryInterface> {
	return useContext(DictionaryContext);
}
