import { RequestInterface } from 'application/connectors/cms/_api';
import { getDictionaryFromCDA, getDictionaryFromCms } from 'application/connectors/cms/dictionaryConnector';
import {
	AbbreviationItems,
	AbbreviationKey,
	ActionItems,
	ActionKey,
	DocumentItems,
	DocumentKey,
	ErrorItems,
	ErrorKey,
	ExplainerItems,
	ExplainerKey,
	LabelItems,
	LabelKey,
	NewsletterItems,
	NewsletterKey,
	PreviewItems,
	PreviewKey,
	QuizItems,
	QuizKey,
	SearchItems,
	SearchKey,
	ServiceInfoItems,
	ServiceInfoKey,
} from './dictionaries';

export interface DictionaryInterface {
	getAbbreviation(key: AbbreviationKey): string;
	getAction(key: ActionKey): string;
	getDocument(key: DocumentKey): string;
	getExplainer(key: ExplainerKey): string;
	getLabel(key: LabelKey): string;
	getSearch(key: SearchKey): string;
	getPreview(key: PreviewKey): string;
	getError(key: ErrorKey): string;
	getNewsletter(key: NewsletterKey): string;
	getServiceInfo(key: ServiceInfoKey, replacements?: string[]): string;
	getQuiz(key: QuizKey): string;
}

export const DictionaryFactory = (items: Content.DictionaryItem[] | Models.Dictionary, culture: string): DictionaryInterface => {
	const dictionaryComesFromCDA = 'items' in items;

	if (!dictionaryComesFromCDA) {
		const getValue = (key: string, replacements?: string[], defaultValue?: string) => {
			const dictionaryValue: Content.DictionaryItem = items.find((element) => element?.key?.toLocaleLowerCase() === key.toLocaleLowerCase());
			let dictionaryString = dictionaryValue?.values?.find(
				(element) => element?.culture?.toLocaleLowerCase() === culture?.toLocaleLowerCase(),
			)?.value;
			if (!dictionaryString) {
				if (!defaultValue) {
					//Cannot find dictionary text and no default supplied - in development we use a default error text
					return process.env.NODE_ENV === 'development' ? `[DICTIONARY ${key} MISSING FOR ${culture}]` : null;
				} else {
					dictionaryString = defaultValue;
				}
			}

			if (!replacements) {
				return dictionaryString;
			}
			replacements.forEach((replacement, index) => {
				return (dictionaryString = dictionaryString.replace(`{value${index + 1}}`, replacement));
			});

			return dictionaryString;
		};

		return {
			getAbbreviation: (key: AbbreviationKey) => AbbreviationItems(getValue).get(key),
			getAction: (key: ActionKey) => ActionItems(getValue).get(key),
			getDocument: (key: DocumentKey) => DocumentItems(getValue).get(key),
			getExplainer: (key: ExplainerKey) => ExplainerItems(getValue).get(key),
			getLabel: (key: LabelKey) => LabelItems(getValue).get(key),
			getSearch: (key: SearchKey) => SearchItems(getValue).get(key),
			getPreview: (key: PreviewKey) => PreviewItems(getValue).get(key),
			getError: (key: ErrorKey) => ErrorItems(getValue).get(key),
			getNewsletter: (key: NewsletterKey) => NewsletterItems(getValue).get(key),
			getServiceInfo: (key: ServiceInfoKey, replacements?: string[]) => ServiceInfoItems((key) => getValue(key, replacements)).get(key),
			getQuiz: (key: QuizKey) => QuizItems(getValue).get(key),
		};
	}

	if (dictionaryComesFromCDA) {
		const getValue = (key: string, replacements?: string[], defaultValue?: string) => {
			const dictionaryItem = items.items.find((item) => item?.key?.toLocaleLowerCase() === key.toLocaleLowerCase());
			let dictionaryString = dictionaryItem?.value;
			if (!dictionaryItem) {
				if (!defaultValue) {
					//Cannot find dictionary text and no default supplied - in development we use a default error text
					return process.env.NODE_ENV === 'development' ? `[DICTIONARY ${key} MISSING FOR ${culture}]` : null;
				} else {
					dictionaryString = defaultValue;
				}
			}

			if (!replacements) {
				return dictionaryString;
			}
			replacements.forEach((replacement, index) => {
				return (dictionaryString = dictionaryString.replace(`{value${index + 1}}`, replacement));
			});
			return dictionaryString;
		};
		return {
			getAbbreviation: (key: AbbreviationKey) => AbbreviationItems(getValue).get(key),
			getAction: (key: ActionKey) => ActionItems(getValue).get(key),
			getDocument: (key: DocumentKey) => DocumentItems(getValue).get(key),
			getExplainer: (key: ExplainerKey) => ExplainerItems(getValue).get(key),
			getLabel: (key: LabelKey) => LabelItems(getValue).get(key),
			getSearch: (key: SearchKey) => SearchItems(getValue).get(key),
			getPreview: (key: PreviewKey) => PreviewItems(getValue).get(key),
			getError: (key: ErrorKey) => ErrorItems(getValue).get(key),
			getNewsletter: (key: NewsletterKey) => NewsletterItems(getValue).get(key),
			getServiceInfo: (key: ServiceInfoKey, replacements?: string[]) => ServiceInfoItems((key) => getValue(key, replacements)).get(key),
			getQuiz: (key: QuizKey) => QuizItems(getValue).get(key),
		};
	}
};

export const getDictionaryItems = async ({
	pageContext,
	fromCDA = false,
}: RequestInterface & { fromCDA?: boolean }): Promise<Content.DictionaryItem[] | Models.Dictionary> => {
	return fromCDA ? await getDictionaryFromCDA(pageContext) : await getDictionaryFromCms(pageContext);
};
